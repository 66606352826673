<template>
  <div class="view-task">
    <a-table tid="3" :size="tableSize" :columns="columns" :data-source="taskList" :rowClassName="rowClassName" rowKey="id" :pagination="false">
      <div class="task-name" slot="taskName" slot-scope="text, record">
        <span class="task-name-custom"> {{ record.taskName }}</span>
      </div>
      <span slot="taskProgress" slot-scope="text, record">
        <a-progress :percent="record.taskProgress" :status="getTaskProgressStatus(record)"></a-progress>
      </span>
      <div class="light-gray" slot="createBy" slot-scope="text, record">
        <span> {{ record.createBy }}</span>
      </div>
      <div slot="routeName" slot-scope="text, record">
        <a class="route-name" href="#">
          <span @click="toViewRoute(record)">{{ record.routeName }}</span></a
        >
      </div>
      <div slot="taskState" slot-scope="text, record">
        {{ taskStateFormat(record) }}
      </div>
      <div slot="taskType" slot-scope="text, record">
        {{ taskTypeFormat(record) }}
      </div>
      <div slot="taskSource" slot-scope="text, record">
        {{ taskSourceFormat(record) }}
      </div>
      <div slot="vehicleCreate" slot-scope="text, record">
        {{ vehicleCreateFormat(record) }}
      </div>
      <div slot="action" slot-scope="text, record">
        <template v-if="record.taskState === '0'">
          <a @click="updateSendTask(record)"> <a-icon type="info-circle" />修改 </a>
          <a-divider type="vertical" />
        </template>
        <!-- 车辆任务未开始, 4代表正在运行中-->
        <template v-if="record.taskState == '0'">
          <a @click="handleCancelTask(record)"> <a-icon type="info-circle" />下架任务 </a>
          <a-divider type="vertical" />
        </template>
        <!-- 车辆任务未开始 -->
        <template v-if="record.schedulerType === 2 && record.vehicleCreate == 0 && record.taskState == '0'">
          <a @click="handleTaskStart(record)"> <a-icon type="info-circle" />开始任务 </a>
          <a-divider type="vertical" />
        </template>

        <template>
          <template v-if="record.taskStateRecord == '4'">
            <a @click="handlePauseTask(record.taskRecordId)" v-hasPermi="['tenant:iot:vehicleTaskRecord:edit']"> <a-icon type="edit" />暂停 </a>
            <a-divider type="vertical" v-hasPermi="['tenant:iot:vehicleTaskRecord:edit']" />
            <a @click="handleStopTask(record.taskRecordId)" v-hasPermi="['tenant:iot:vehicleTaskRecord:edit']"> <a-icon type="edit" />停止 </a>
            <a-divider type="vertical" v-hasPermi="['tenant:iot:vehicleTaskRecord:edit']" />
          </template>
          <template v-if="record.taskStateRecord == '1'">
            <a @click="handleStopTask(record.taskRecordId)" v-hasPermi="['tenant:iot:vehicleTaskRecord:edit']"> <a-icon type="edit" />停止 </a>
            <a-divider type="vertical" v-hasPermi="['tenant:iot:vehicleTaskRecord:edit']" />
          </template>
          <template v-if="record.taskStateRecord == '1'">
            <a @click="handleResumeTask(record.taskRecordId)" v-hasPermi="['tenant:iot:vehicleTaskRecord:edit']"> <a-icon type="edit" />继续 </a>
            <a-divider type="vertical" v-hasPermi="['tenant:iot:vehicleTaskRecord:edit']" />
          </template>
          <a @click="handleNavigateTo(record)"> <a-icon type="info-circle" />任务记录 </a>
        </template>
      </div>
    </a-table>
    <!-- 分页 -->
    <a-pagination
      class="ant-table-pagination"
      show-size-changer
      show-quick-jumper
      :current="queryParam.pageNum"
      :total="total"
      :page-size="queryParam.pageSize"
      :showTotal="(total) => `共 ${total} 条`"
      @showSizeChange="onShowSizeChange"
      @change="changeSize"
    />

    <route-plan-modal v-if="routePlanVisible" :vehicleId="vehicleId" :routeId="curRouteId" @closeRoutePlanModal="handleCloseRoutePlanModal" :position="{ top: '4%', left: '52%' }" />
    <!-- 查看任务详情 -->
    <vxe-modal v-model="TaskDetailVisible" width="80%" height="80%" :title="vehicleId + '-任务记录'" :show-zoom="true" resize :lock-view="false" :mask="false" @close="handleCloseTaskDetailModal">
      <vehicleTaskRecord :breadcrumb="false" :taskId="taskId" />
    </vxe-modal>

    <create-reset-task-modal
      :info="info"
      v-if="createExecTaskVisible"
      @closeTaskModal="handleCloseSendTaskModal"
      :position="{ top: '20%', left: '45%' }"
      :is-show-modal="createExecTaskVisible"
      :vehicle-id="vehicleId"
      :taskForm="taskForm"
      :isTimedTask="isTimedTask"
      @update="handleUpdate"
    />
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { syncTask } from '@/api/iot/vehicleTask'
import RoutePlanModal from '@/views/iot/routePlanning/modules/RoutePlanModal.vue'
import TaskBase from './TaskBase.js'
import vehicleTaskRecord from '@/views/iot/vehicleTaskRecord'
import { mapState } from 'vuex'
import CreateResetTaskModal from './CreateResetTaskModal'
import XEUtils from 'xe-utils'
import { listTaskScheduler, taskStart, cancelTask, taskStartAck, autoResetGet, autoResetGetAck } from '@/api/iot/taskScheduler'
import { Progress } from 'ant-design-vue'
const columns = [
  {
    title: '使用的任务模板',
    dataIndex: 'taskName',
    key: 'taskName',
    scopedSlots: { customRender: 'taskName' },
    width: 150
  },
  {
    title: '固定点名称',
    key: 'remark',
    dataIndex: 'remark',
    width: 150,
    ellipsis: true
  },
  {
    title: 'mapId',
    key: 'mapId',
    dataIndex: 'mapId',
    ellipsis: true,
    width: 200
  },
  {
    title: 'mapVersion',
    key: 'mapVersion',
    dataIndex: 'mapVersion',
    ellipsis: true,
    width: 120
  },
  {
    title: '发起人',
    key: 'createBy',
    dataIndex: 'createBy',
    ellipsis: true,
    scopedSlots: { customRender: 'createBy' },
    width: 100
  },
  {
    title: '任务进度',
    dataIndex: 'taskProgress',
    scopedSlots: { customRender: 'taskProgress' },
    ellipsis: true,
    align: 'center',
    width: 100
  },

  {
    title: '任务状态',
    dataIndex: 'taskState',
    key: 'taskState',
    scopedSlots: { customRender: 'taskState' },
    width: 100
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    width: 260
  }
]

export default {
  mixins: [TaskBase],
  name: 'ViewTaskModal',
  components: { RoutePlanModal, vehicleTaskRecord, CreateResetTaskModal, AProgress: Progress },
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    position: {
      type: Object,
      default: () => {}
    },
    isShowModal: {
      type: Boolean,
      default: () => {}
    },
    vehicleId: {
      type: String,
      default: () => {}
    },
    params: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    // 节流控制
    this.handleViewTaskByName = debounce(this.handleViewTaskByName, 500)
    return {
      columns,
      ModalVisible: this.isShowModal,
      loading: false,
      taskStateOptions: [],
      // 日期范围
      startTimeRange: [],
      // 路由名称
      searchRouteName: '',
      // 查询参数
      queryParam: {
        vehicleId: this.vehicleId,
        taskName: '',
        remark: '',
        taskState: '',
        pageNum: 1,
        pageSize: 10,
        schedulerType: 4
      },
      total: 0,
      routePlanVisible: false,
      curRouteId: '',
      title: this.vehicleId + '-查看任务',
      TaskDetailVisible: false,
      taskId: '',
      taskTypeOptions: [],
      // 创建任务
      createExecTaskVisible: false,
      taskForm: null,
      taskSourceOptions: [],
      isGetData: false,
      isTimedTask: false,
      // 是否有任务正在运行中
      taskIsRunning: false
    }
  },
  computed: {
    ...mapState({
      ackData: (state) => state.websocket.ackData
    })
  },
  created() {
    this.isGetData = true
    this.initTimer()
    this.getData()
    console.log('params', this.params)
  },
  watch: {
    // 同步车端任务：websocket接收数据
    ackData: {
      handler(newVal, oldVal) {
        const data = newVal.data
        if (data && data.type === '7') {
          if (data.result === 888) {
          } else {
            let result = ''
            let infoType = 'error'
            if (data.result === 0) {
              // 同步任务成功
              infoType = ''
              result = '同步车端任务成功'
            } else if (data.result === 98) {
              // 状态错误
              result = '状态错误'
            } else if (data.result === 99) {
              // 内部错误
              result = '内部错误'
            } else if (data.result === 100) {
              // 请求内容错误
              result = '请求内容错误'
            }
            this.toEnd(infoType, result)
          }
        }
      },
      immediate: true
    },
    isShowModal(newVal, oldVal) {
      console.log('isShowModal', newVal)
    }
  },
  methods: {
    syncResetTask() {
      const formData = new FormData()
      formData.append('vehicleId', this.vehicleId)
      this.$loading.show({
        msg: '正在同步车端返回固定点任务设置'
      })
      autoResetGet(formData)
        .then((res) => {
          console.log('autoReset', res)
          // 获取动态任务操作结果
          this.taskIsRunning = true
          this.isClearTimeout = false
          this.taskAckBeginTickount = new Date()
          const params = {
            vehicleId: this.vehicleId
          }
          this.getTaskAck(autoResetGetAck, params, 'autoResetGet')
        })
        .catch((e) => {
          this.$loading.hide()
        })
    },
    rowClassName(record, index) {
      // console.log('rowClassName')
      let className = ''
      if (record.taskState === '4') {
        className = 'rowActive'
      }
      return className
    },
    initTimer() {
      this.taskTimer = setInterval(() => {
        if (this.taskIsRunning) {
          this.getTaskList()
        }
      }, 5000)
    },
    getTaskProgressStatus(record) {
      // console.log('getTaskProgressStatus')
      if (['1', '3', '6', '7', '8'].indexOf(record.taskState) >= 0) {
        return 'exception'
      } else if (record.taskState === '5') {
        return 'success'
      } else {
        return ''
      }
    },
    // 计算任务时长
    calcTaskHour(data) {
      data.forEach((p, idx) => {
        p.planTaskHour = this.getDateDiff(p.startTime, p.taskExpectTime)
        p.realityTaskHour = this.getDateDiff(p.realityStartTime, p.realityExpectTime)
        p.taskStateName = this.gettaskStateName(p.taskState)
      })
      return data
    },
    getDateDiff(startTime, endTime) {
      const dateDiff = XEUtils.getDateDiff(startTime, endTime)
      if (dateDiff.time === 0) {
        return '00:00'
      } else {
        return `${dateDiff.HH}:${dateDiff.MM}`
      }
    },
    handleUpdate() {
      this.getTaskList()
    },
    updateSendTask(record) {
      this.isTimedTask = false
      this.createExecTaskVisible = true
      this.taskForm = record
    },
    resetTaskSetting(record) {
      this.isTimedTask = true
      this.createExecTaskVisible = true
      this.taskForm = record
    },
    handleCloseSendTaskModal() {
      this.createExecTaskVisible = false
    },
    async getData() {
      await this.getDicts('iot_task_taskState').then((response) => {
        this.taskStateOptions = response.data
      })
      await this.getDicts('iot_vehicle_task_type').then((response) => {
        this.taskTypeOptions = response.data
      })
      // 车端回传结果状态
      await this.getDicts('iot_vehicle_remoteCtlResult').then((response) => {
        this.vehicleRemoteCtlResultOptions = response.data
      })
      // 任务来源
      await this.getDicts('iot_task_source').then((response) => {
        this.taskSourceOptions = response.data
      })
      this.getListData()
    },
    getListData() {
      if (this.params && typeof this.params === 'object') {
        this.vehicleId = this.params.vehicleId
        this.queryParam.vehicleId = this.vehicleId
        // 页面返回，同时不要求页面数据更新，则不重新读取数据
        if (this.params.navigateType === 'back' && !this.params.navigateBackUpdate) {
          return
        }
      }
      this.getTaskList()
    },
    vehicleCreateFormat(record) {
      const vehicleCreate = record.vehicleCreate
      if (vehicleCreate === 0) {
        return '创建成功'
      } else {
        return '未创建'
      }
    },
    taskStateFormat(row) {
      return this.selectDictLabel(this.taskStateOptions, row.taskState)
    },
    taskTypeFormat(row) {
      return this.selectDictLabel(this.taskTypeOptions, row.taskType)
    },
    taskSourceFormat(row) {
      return this.selectDictLabel(this.taskSourceOptions, row.taskSource)
    },
    handleCloseTaskModal() {
      this.clearTimeoutEx()
      this.$emit('closeViewTaskModal')
    },
    // 查看任务详情关闭
    handleCloseTaskDetailModal() {
      this.TaskDetailVisible = false
    },
    handleViewTaskBytaskState() {
      console.log('tasktaskState', this.queryParam.tasktaskState)
      this.getTaskList()
    },
    handleViewTaskByName(value) {
      console.log('handleViewTaskByName', value)
      this.queryParam.taskName = value
      this.getTaskList()
    },
    /** 查询车辆任务列表 */
    getTaskList(queryParam) {
      if (queryParam) {
        this.queryParam = Object.assign(this.queryParam, queryParam)
      }
      this.queryParam.schedulerType = 4
      this.loading = true
      listTaskScheduler(this.queryParam).then((response) => {
        // this.taskList = this.calcTaskHour(response.rows)
        this.taskList = response.rows.map((p) => {
          // 未开始任务进度为0
          if (p.taskState === '0') {
            p.taskProgress = 0
          }
          return p
        })
        const obj = this.taskList.find((p) => p.taskState === '4')
        if (obj) {
          this.taskIsRunning = true
        } else {
          this.taskIsRunning = false
        }
        this.total = response.total
        this.loading = false
        this.isGetData = false
        this.$forceUpdate()
        // console.log('taskList', this.taskList)
      })
    },
    handleCloseRoutePlanModal() {
      this.routePlanVisible = false
    },
    handleChangeRoute(record) {
      this.curRouteId = record.taskRouteId
      this.routePlanVisible = true
      console.log('handleChangeRoute')
    },
    toViewRoute(record) {
      console.log('toViewRoute')
      this.$emit('viewRoute', record.taskRouteId)
    },
    handleCancelTask(record) {
      this.$confirm({
        title: '确认要下架任务吗?',
        content: '',
        zIndex: 99999,
        onOk: () => {
          const formData = new FormData()
          formData.append('id', record.id)
          cancelTask(formData)
            .then((response) => {
              this.getTaskList()
              this.$successEx('下架任务成功！')
            })
            .catch((e) => {
              console.log(e)
            })
        },
        onCancel() {}
      })
    },
    // 执行任务
    handleTaskStart(record) {
      this.$loading.show({
        msg: '正在执行任务'
      })
      const formData = new FormData()
      formData.append('id', record.id)
      formData.append('vehicleId', this.vehicleId)
      this.tips = '正在执行任务'
      taskStart(formData)
        .then((response) => {
          const taskStartId = response.data
          console.log('taskStart', taskStartId)
          this.taskIsRunning = true
          this.isClearTimeout = false
          this.taskAckBeginTickount = new Date()
          const params = {
            vehicleId: this.vehicleId
          }
          this.getTaskAck(taskStartAck, params, 'start')
        })
        .catch((e) => {
          console.log(e)
          this.$loading.hide()
        })
    },

    toEnd(infoType, result) {
      // if (!this.spinning && !this.isSendingTask) {
      //   return
      // }
      this.$loading.hide()
      let myModal = this.$successEx
      if (infoType === 'warn') {
        myModal = this.$warningEx
      } else if (infoType === 'error') {
        myModal = this.$errorEx
      }
      myModal(result, () => {
        this.clearTimeoutEx()
        this.getTaskList()
      })
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getTaskList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getTaskList()
    },
    handleSyncTask() {
      this.sendTaskTitle = '同步车端任务'
      const formData = new FormData()
      formData.append('vehicleId', this.vehicleId)
      this.tips = '正在同步车端任务'
      syncTask(formData)
        .then((res) => {
          this.isSendingTask = true
          this.spinning = true
          // 获取车端任务状态
          this.isClearTimeout = false
          this.getStatusStartTickcount = new Date()
          // 任务超时处理
          this.getSyncTaskStatus()
        })
        // Promise reject时回调
        .catch((e) => {
          this.spinning = false
        })
    },
    handleNavigateTo(record) {
      this.$emit('navigateTo', 'taskRecord', {
        taskId: record.id,
        taskTypeOptions: this.taskTypeOptions,
        breadcrumb: false,
        parentTitle: this.vehicleId + '-' + record.taskName + '-'
      })
    },
    handleStartTimeRangeOk(value) {
      console.log('handleStartTimeRangeOk', value)
      this.getTaskList()
    },
    handleSchedulerType(e) {
      this.getTaskList()
    }
  },
  beforeDestroy() {
    console.log('ViewTaskModal', 'beforeDestroy')
    this.isClearTimeout = true
    this.clearTimeoutEx()
    this.taskTimer && clearInterval(this.taskTimer)
  },
  activated() {
    // 页面激活
    console.log('viewTask-activated', this.params)
    // 返回如何控制是否需要刷新页面
    this.getListData()
  },
  deactivated() {
    console.log('viewTask-deactivated', this.params)
    this.isClearTimeout = true
    this.clearTimeoutEx()
  }
}
</script>
<style lang="less">
.task-name {
  span {
    display: block;
  }
  & > span:nth-child(1) {
    margin-bottom: 5px;
    font-weight: bold;
    color: #272727;
  }
}
.view-task {
  .light-gray {
    color: #8d8d8d;
  }
  .route-name {
    text-decoration: underline;
  }
  .task-header {
    display: inline;
  }
  .span-margin-left {
    margin-left: 20px;
    margin-right: 20px;
    width: 260px;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
  }
}
.task-result {
  display: flex;
  justify-content: center;
  align-items: center;
}

.view-task {
  .vxe-modal--wrapper .vxe-modal--content > div {
    height: auto;
  }
  .ant-spin-nested-loading {
    height: 100% !important;
  }
}
.task-search {
  display: flex;
  align-items: center;
  .search-item {
    margin-left: 10px;
  }
  .ant-radio-group {
    min-width: 250px;
  }
}
.rowActive {
  background: #e6f7ff;
}
</style>
